import request from "../plugins/axios";

export function add(params) {
  return request({
    url: '/roomMessageBatchSend/store',
    method: 'POST',
    data: params
  })
}
export function roomMessageBatchSend(params) {
  return request({
    url: '/roomMessageBatchSend/index',
    method: 'GET',
    params: params
  })
}
export function roomMessageBatchSendShow(params) {
  return request({
    url: '/roomMessageBatchSend/show',
    method: 'GET',
    params: params
  })
}
