<template>
  <div class="index">
    <div class="content">
      <div class="title">{{ details.batchTitle }}</div>
      <div class="date">{{ details.createdAt }} 由 {{details.creator}} 发布</div>
      <van-divider />
      <div class="date-details">
        <span style="white-space: nowrap">发送时间：</span>
        <div style="width: 100%;">{{ details.sendTime }}</div>
      </div>
      <div class="date-details">
        <span style="white-space: nowrap">发送给：</span>
        <div style="width: 100%;">{{ String(details.sendRooms) || '暂无' }}</div>
      </div>
      <van-divider />
      <div class="file-details">
        <span>发送内容：</span>
        <ul>
          <div v-for="(v,i) in details.content" :key="i">
            <li v-if="v.msgType == 'text'">{{ v.content }}</li>
            <li v-if="v.msgType == 'image'">
              <img :src="v.fullPath" alt="">
              <div>
                <span>{{v.name}}</span>
                <i>图片</i>
              </div>
            </li>
            <li v-if="v.msgType == 'link'">
              <img :src="v.fullPath" alt="">
              <div>
                <span>{{v.title}}</span>
                <i>{{ v.url }}</i>
              </div>
            </li>
            <li v-if="v.msgType == 'miniprogram'">
              <img :src="v.pic_url" alt="">
              <div>
                <span>{{v.title}}</span>
                <i>小程序</i>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {roomMessageBatchSendShow} from "../../api/roomMessageBatchSend";
export default {
  data() {
    return {
      details: {}
    }
  },
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.detailsData()
  },
  methods: {
    detailsData(){
      roomMessageBatchSendShow({batchId: this.id}).then(res=>{
        this.details = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;
  .content {
    background: #fff;
    padding: 12px 16px;
    .title{
      color: #000;
      font-size: 18px;

      width: 201px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
    .date{
      color: #939393;
      font-size: 12px;
      margin-top: 5px;
    }
    .date-details{
      display: flex;
      font-size: 13px;
      margin-bottom: 5px;
      span{
        color: #939393;
      }
    }
    .file-details{
      span{
        color: #939393;
        font-size: 13px;
      }
      ul{
        li{
          background: #f4f5f7;
          min-height: 50px;
          width: 100%;
          border-radius: 12px;
          font-size: 14px;
          /* padding: 7px 10px; */
          display: flex;
          align-items: center;
          padding: 0 12px;
          margin-top: 10px;
          div{
            display: flex;
            flex-direction: column;
            i{
              color: #939393;
              font-size: 12px;
              font-style: normal;
            }
            span{
              color: #000;
              width: 201px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          img{
            width: 45px;
            margin: 10px 0px;
            border-radius: 3px;
            margin-right: 7px;
          }
        }
      }
    }
  }
}
</style>
